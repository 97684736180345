import {REST_BASE, REST_INIT, REST_LAST_MODIFIED, REST_PAST_EVENTS} from "./constants";

export const fetchInit = async () => {
  console.log("FETCH INIT!");
  const response = await fetch( REST_BASE + REST_INIT );
  if(!response.ok) {
    throw new Error('Failed to fetch theme settings!');
  }
  const data = await response.json();
  if(data.page === undefined) {
    throw new Error('no page defined!');
  }
  return data;
};


export const fetchPastEvents = async () => {
  console.log("FETCH PAST EVENTS!");
  const response = await fetch( REST_BASE + REST_PAST_EVENTS );
  if(!response.ok) {
    throw new Error('Failed to fetch theme settings!');
  }
  const data = await response.json();
  return data;
};


export const fetchLastModified = async (ids) => {
  const response = await fetch( REST_BASE + REST_LAST_MODIFIED + '?ids=' + JSON.stringify(ids));
  if( !response.ok ) throw new Error( 'Failed to fetch last modified date.' );
  return await response.json();
};