import React from "react";
import styles from './Downloads.module.scss';

import { ReactComponent as ZIP } from '../../assets/images/zip.svg';
import { ReactComponent as Text } from '../../assets/images/text.svg';
import Image from "../Image/Image";

const Downloads = ( props ) => {

  let nr_items = props.attrs.data['block_downloads_downloads'];
  let items = [];

  for(let i = 0; i < nr_items; i++) {
    items[i] = {
      'title': props.attrs.data['block_downloads_downloads_' + i + '_block_downloads_title'],
      'type': props.attrs.data['block_downloads_downloads_' + i + '_block_downloads_type'],
      'file': props.attrs.data['block_downloads_downloads_' + i + '_block_downloads_file'],
      'image': props.attrs.data['block_downloads_downloads_' + i + '_block_downloads_image'],
    }
  }

  let downloads = '';
  downloads = items.map(function(item, index) {
    let link = item.type === 'image' ? item.image.sizes['full']['src'] : item.file.url;
    let image;
    if(item.type === 'image') {
      image = <div className={styles.image}>
        <Image
          src={item.image['sizes']['medium']['src']}
          alt={item.image['alt']}
          width={item.image['sizes']['medium']['width']}
          height={item.image['sizes']['medium']['height']}
          lazyload={true}
        />
      </div>
    }
    else if(link.endsWith(".zip")) {
      image = <ZIP className={styles.zip} />
    }
    else {
      image = <Text className={styles.zip} />
    }

    return (
      <a href={link} download key={index} className={styles.download}>
        <div className={styles.imageHolder}>
          {image}
        </div>
        <div className={styles.title}>
          {item.title}
        </div>
      </a>
    );
  });


  return (
    <div className={styles.downloads}>
      {downloads}
    </div>
  );
};

export default Downloads;
