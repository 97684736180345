import React from "react";

import styles from './Events.module.scss';
import Ticket from './Ticket';
import classNames from "classnames/bind";
const classes = classNames.bind(styles);

const Event = ( props ) => {

  const event = props.event;

  return (
    <tr className={classes({
      'eventRow': true,
      'available': event.status === 'available',
      'soldOut': event.status === 'sold_out',
      'cancelled': event.status === 'cancelled',
    })}>
      <td className={styles.date}>{event.date}</td>
      <td className={styles.venue}>{event.venue}</td>
      <td className={styles.location} colSpan={props.past ? 2 : 1}>{event.place}</td>
      {
        props.past ? <td></td> : <td><Ticket link={event.tickets} status={event.status}></Ticket></td>
      }
    </tr>
  );
};
export default Event;
