import React from "react";
import styles from './Content.module.scss';

import Container from '../Layout/Container'

import About from '../About/About'
import Contact from '../Contact/Contact'
import Events from '../Events/Events'
import Media from '../Media/Media'
import PageIntro from '../PageIntro/PageIntro'
import Downloads from '../Downloads/Downloads'
import ImageText from '../ImageText/ImageText'
import parse from "html-react-parser";

const Content = ( props ) => {

  let blocks = props.page ? props.page.blocks : [];
  let content = [];

  for (const [key, block] of Object.entries(blocks)) {

    let blockContent;
    let blockName   = block['blockName'].replace('acf/','');
    let isFullWidth = block['attrs']['data']['block_layout_'+blockName+'_container'] === 'full';

    let paddingTop    = 'padding-top-' + block['attrs']['data']['block_layout_'+blockName+'_padding_top'];
    let paddingBottom = 'padding-bottom-' + block['attrs']['data']['block_layout_'+blockName+'_padding_bottom'];
    let paddingTopMobile    = 'padding-top-m-' + block['attrs']['data']['block_layout_'+blockName+'_padding_top_mobile'];
    let paddingBottomMobile = 'padding-bottom-m-' + block['attrs']['data']['block_layout_'+blockName+'_padding_bottom_mobile'];
    let anchor        = block['attrs']['data']['block_layout_'+blockName+'_anchor'];

    switch( blockName ) {
      case 'page-intro':
        blockContent = <PageIntro attrs={block['attrs']}></PageIntro>;
        break;
      case 'events':
        blockContent = <Events attrs={block['attrs']}></Events>;
        break;
      case 'about':
        blockContent = <About attrs={block['attrs']}></About>;
        break;
      case 'media':
        blockContent = <Media attrs={block['attrs']}></Media>;
        break;
      case 'contact':
        blockContent = <Contact attrs={block['attrs']}></Contact>;
        break;
      case 'text':
        blockContent = <div className={styles.text}>{parse(block['attrs'].data['block_text_text'])}</div>;
        break;
      case 'downloads':
        blockContent = <Downloads attrs={block['attrs']}></Downloads>;
        break;
      case 'image-text':
        blockContent = <ImageText attrs={block['attrs']}></ImageText>;
        break;
      default:
        blockContent = <p key={key}>unrecognized block "{block['blockName']}"</p>;
    }

    content.push(
      <Container key={key}
                 paddingTop={paddingTop}
                 paddingBottom={paddingBottom}
                 paddingTopMobile={paddingTopMobile}
                 paddingBottomMobile={paddingBottomMobile}
                 anchor={anchor}
                 fullWidth={isFullWidth}
      >
        {blockContent}
      </Container>);
  }

  return <div className={styles.mainContent} id={'main-content'}>{content}</div>
};

export default Content;
