import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoaded: false,
  menus: undefined,
  options: undefined,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    setMenus(state, action) {
      state.menus = action.payload;
    },
    setOptions(state, action) {
      state.options = action.payload;
    },
    setIsLoaded(state, action) {
      state.isLoaded = action.payload
    }
  }
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;
