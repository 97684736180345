import React, {useState} from "react";

import styles from './Contact.module.scss';
import Title from "../Title/Title";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import classNames from "classnames/bind";
import { ReactComponent as Success } from '../../assets/images/success.svg';

const classes = classNames.bind(styles);

const Contact = ( props ) => {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  let inputChange = function(e) {
    e.target.parentNode.classList.remove(styles.hasError);
  }
  let checkboxChange = function(e) {
    e.target.parentNode.parentNode.classList.remove(styles.hasError);
  }



  let submitForm = function(e) {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    let valid = true;

    let requiredFields = [
      'name',
      'mail',
      'message',
      'privacy',
    ];

    for(let i = 0; i < requiredFields.length; i++) {
      let field = document.getElementById(requiredFields[i]);
      if((field.type === 'text' || field.type === 'textarea') && field.value === '') {
        valid = false;
        field.parentNode.classList.add(styles.hasError);
      }
      else if(field.type === 'checkbox' && field.checked === false) {
        valid = false;
        field.parentNode.parentNode.classList.add(styles.hasError);
      }
    }

    if(valid) {
      setTimeout(function() {
        const sendForm = async () => {
          let response = await fetch( '/wp-json/api/v1/contact/', {
            method: 'POST',
            body: JSON.stringify({
              name: document.getElementById('name').value,
              mail: document.getElementById('mail').value,
              message: document.getElementById('message').value,
              privacy: document.getElementById('privacy').checked,
            })
          });
          if(!response.ok) {
            throw new Error('Failed to post contact form!');
          }
          return await response.json();
        };

        sendForm().then((data) => {
          if(data.success) {
            setLoading(false);
            setSuccess(true);
          }
          else {
            setMessage(data.data.message);
            setLoading(false);
            for(let i = 0; i < data.data.invalid_fields.length; i++) {
              document.getElementById(data.data.invalid_fields[i]).parentNode.classList.add(styles.hasError);
            }
          }
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
        }, 500);
    }
    else {
      setMessage('Bitte füllen sie alle Felder richtig aus!');
      setLoading(false);
    }
  }


  return (
    <React.Fragment>
      <Title>contact</Title>

      <form className={classes({
        'form': true,
        'isLoading': loading,
        'success': success,
      })} id={'form'}>
        <Row>
          <Column width={6} breakpoints={{ m: 12 }}>
            <div className={styles.oInput}>
              <label className={styles.oInputLabel}>Name*</label>
              <input className={styles.oInputField}
                     id={'name'}
                     required
                     onChange={inputChange} />
            </div>
          </Column>

          <Column width={6} breakpoints={{ m: 12 }}>
            <div className={styles.oInput}>
              <label className={styles.oInputLabel}>E-Mail*</label>
              <input className={styles.oInputField}
                     id={'mail'}
                     required
                     onChange={inputChange} />
            </div>
          </Column>
        </Row>

        <Row>
          <Column width={12}>
            <div className={styles.oInput}>
              <label className={styles.oInputLabel}>Nachricht*</label>
              <textarea className={styles.oInputField}
                        rows={5} id={'message'}
                        required
                        onChange={inputChange} ></textarea>
            </div>
          </Column>
        </Row>

        <Row>
          <Column width={12}>
            <div className={styles.oInput}>
              <label className={styles.oInputCheckboxLabel}>
                <input type={'checkbox'}
                       className={styles.oCheckbox}
                       id={'privacy'}
                       required
                       onChange={checkboxChange} />
                Ich habe die <a href={'/datenschutzerklaerung'}>Datenschutzerklärung</a> gelesen und zur Kenntniss genommen.*
              </label>
            </div>

            <div className={styles.messageHolder}>
              <div className={classes({
                'message': true,
                'isVisible': message !== '',
              })}>
                {message}
              </div>
            </div>
          </Column>
        </Row>

        <Row>
          <Column width={8}  breakpoints={{ m: 6 }}></Column>
          <Column width={4} breakpoints={{ m: 6 }}>
            <button type={'submit'}
                   className={styles.oButton}
                   onClick={submitForm}>
              Absenden
            </button>
          </Column>
        </Row>
      </form>

      <div className={classes({
        'success': true,
        'isVisible': success,
      }
      )}>
        <Success className={styles.successIcon} />
        <div>
          Danke für deine Nachricht!
        </div>
        <div className={styles.subtext}>
        Wir melden uns so schnell wie möglich bei dir.
        </div>
      </div>
    </React.Fragment>
  );
};
export default Contact;
