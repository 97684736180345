import { combineReducers, configureStore } from "@reduxjs/toolkit";
import theme from './reducers/theme';
import pages from "./reducers/pages";
import page from "./reducers/page";
import updated from "./reducers/page-updates";
import events from "./reducers/events";
import storage from 'redux-persist/lib/storage';

import {EXPIRE_SECONDS} from "../util/constants"

import { persistReducer } from "redux-persist";
import {
  FLUSH,
  PERSIST,
  PAUSE,
  REHYDRATE,
  PURGE,
  REGISTER
} from "redux-persist/es/constants";

const expireReducer = require('redux-persist-expire');

const reducers = combineReducers( {
  theme: theme,
  pages: pages,
  page: page,
  updated: updated,
  events: events,
} );

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [ 'page' ],
  transforms: [
    expireReducer('courses', {
      persistedAtKey: '__persisted_at',
      expireSeconds: EXPIRE_SECONDS,
      expiredState: {
        courses: {}
      },
      autoExpire: true,
    })
  ]
};

const persistedReducer = persistReducer( persistConfig, reducers );

const store = configureStore( {
  reducer: persistedReducer,
  middleware: ( getDefaultMiddleware => getDefaultMiddleware( {
    serializableCheck: {
      ignoreActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
    }
  } ) )
} );
export default store;