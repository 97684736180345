import React, {useEffect} from "react";

import styles from './Media.module.scss';
import Title from "../Title/Title";
import MediaItem from "./MediaItem";

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';



const Media = ( props ) => {


  let nr_items = props.attrs.data['block_media_items'];
  let items = [];

  for(let i = 0; i < nr_items; i++) {
    items[i] = {
      'image': props.attrs.data['block_media_items_' + i + '_block_media_image'],
      'layout': props.attrs.data['block_media_items_' + i + '_block_media_layout'],
      'type': props.attrs.data['block_media_items_' + i + '_block_media_type'],
      'video': props.attrs.data['block_media_items_' + i + '_block_media_video'],
      'yt_links': props.attrs.data['block_media_items_' + i + '_block_media_yt_links'],
    }
  }

  const mediaItems = items.map((item, i) =>
    <MediaItem key={i}
               item={item}
    ></MediaItem>
  );

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#mediaGallery',
      children: 'a',
      pswpModule: () => import('photoswipe')
    });
    lightbox.init();
  }, []);


  if(!nr_items || nr_items === 0) {
    return '';
  }



  return (
    <React.Fragment>
      <Title>media</Title>

      <div className={styles.itemsWrapper} id={'mediaGallery'}>
        {mediaItems}
      </div>
    </React.Fragment>
  );
};
export default Media;
