import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: undefined,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    fillEvents(state, action) {
      state.events = action.payload;
    },
  }
});

export const eventsActions = eventsSlice.actions;
export default eventsSlice.reducer;
