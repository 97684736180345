import React from "react";

import styles from './Events.module.scss';
import classNames from "classnames/bind";
const classes = classNames.bind(styles);

const Ticket = ( props ) => {

  const status = props.status;
  let text;

  switch( status ) {
    case 'sold_out':
      text = 'sold out';
      break;
    case 'cancelled':
      text = 'cancelled';
      break;
    default:
      text = props.link ? <a href={props.link['url']} target={'_blank'} rel={"noreferrer"}>tickets</a> : "tickets";
  }

  return (
    <div className={classes({
        'ticket': true,
        'soldOut': status === 'sold_out',
        'cancelled': status === 'cancelled',
      })}>
      <span className={styles.ticketText}>
        {text}
      </span>
    </div>
  );
};
export default Ticket;
