import React from "react";
import classNames from "classnames/bind";

import styles from './Media.module.scss';
import {ReactComponent as Youtube} from "../../assets/images/youtube.svg";
import {useSelector} from "react-redux";
import Image from "../Image/Image";
const cn = classNames.bind(styles);


const MediaItem = ( props ) => {

  const item = props.item;
  const youtube_link = useSelector( state => state.theme.options.youtube );

  let ytClick = ( e ) => {
    e.preventDefault();
    window.open( youtube_link, '_blank' ).focus();
  }

  let yt_videos = '';
  if( typeof item.yt_links !== 'undefined' && item.yt_links !== '' ) {
    const links = item.yt_links.split(', ');
    yt_videos = links.map((link) => {
      return (
        <iframe
          width="560"
          height="315"
          src={"https://www.youtube.com/embed/" + link + "?si=aIkekZwRFedBI96e&amp;controls=0"}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen></iframe>
      );
    })
  }


  const content = item.type === 'image' ? (
    <div className={styles.itemImage}>
      <Image
        src={item.image['sizes']['full']['src']}
        alt={item.image['alt']}
        width={item.image['sizes']['full']['width']}
        height={item.image['sizes']['full']['height']}
        lazyload={true}
      />
    </div>
  ) : (
    <React.Fragment>
      <video className={styles.itemVideo} controls>
        <source src={item.video.url} type="video/mp4"/>
        <source src={item.video.url} type="video/webm"/>
      </video>
      <div className={styles.yt_videos}>
        {yt_videos}
      </div>
      <div className={styles.videoText}>
        <div className={styles.videoLink} onClick={ytClick}>
          <div className={styles.videoInner}>
            <Youtube className={styles.videoIcon}/>
            watch more videos on YouTube <span className={styles.normalFont}>!</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  let onclick = ( e ) => {
    e.preventDefault();
  }

  if( item.type === 'video' ) {
    return (
      <div className={cn( {
        'item': true,
        'video': true,
        'rectangle': item.layout === '2x1',
      } )}>
        {content}
      </div>
    );
  }

  return (
    <a className={cn( {
      'item': true,
      'image': true,
      'rectangle': item.layout === '2x1',
    } )}
       onClick={onclick}
       href={typeof item.image !== 'undefined' ? item.image['sizes']['full']['src'] : ''}
       data-pswp-width={typeof item.image !== 'undefined' ? item.image['sizes']['full']['width'] : ''}
       data-pswp-height={typeof item.image !== 'undefined' ? item.image['sizes']['full']['height'] : ''}
    >
      {content}
    </a>
  );
};
export default MediaItem;
