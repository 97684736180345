import React from "react";

import styles from './SubTitle.module.scss';

const SubTitle = ( props ) => {

  return (
    <div className={styles.title}>
      {props.children}
    </div>
  );
};
export default SubTitle;
