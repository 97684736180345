import React from "react";
import Loader from "../Loader/Loader";
import Container from "../Layout/Container";
import Content from "../Layout/Content";
import { useSelector } from "react-redux";

import styles from './Page.module.scss';
import PageUpdater from "../PageUpdater/PageUpdater";

const Page = ( props ) => {

  const isLoaded = useSelector(state => state.theme.isLoaded);
  const page = useSelector(state => state.page.currentPage);
  let output;

  if(typeof page !== 'undefined' && page !== false && isLoaded) {
      output = <Content page={page}></Content>;
  }
  else if (typeof page !== 'undefined' && page === false && isLoaded) {
    output = (
      <div className={styles.page404}>
        <Container>
          <div className={styles.title}>{'404'}</div>
          <div className={styles.text}>{'Sorry, the page you are looking for does not exist.'}</div>
        </Container>
      </div>
    );
  }
  else {
    output = <Loader withWrapper={true}></Loader>
  }


  return (
    <React.Fragment>
      <PageUpdater/>
      {output}
    </React.Fragment>
  );
};

export default Page;
