import React from "react";

import styles from './Loader.module.scss';


const Loader = ( props ) => {

  if(props.withWrapper) {
    return (
      <div className={styles.loaderWrapper}>
        <div className={styles.loader}>
          {/*<div className={styles.inner}></div>*/}
          {/*<div className={styles.inner}></div>*/}
          {/*<div className={styles.inner}></div>*/}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.loader}>
      <div className={styles.inner}></div>
      <div className={styles.inner}></div>
      <div className={styles.inner}></div>
    </div>
  );
};
export default Loader;
