import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Image = ( props ) => {

  if(props.lazyload) {
    return (
      <LazyLoadImage
        src={props.src}
        alt={props.alt}
        width={props.width}
        height={props.height}
      />
    );
  }

  return (
    <img src={props.src}
         alt={props.alt}
         width={props.width}
         height={props.height}
    />
  );
};
export default Image;
