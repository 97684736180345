import React, {useLayoutEffect, useState} from 'react';
import './App.css';
import './Breakpoints.scss';
import {BrowserRouter, Route} from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import {eventsActions} from "./stores/reducers/events";
import {themeActions} from "./stores/reducers/theme";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import {pageActions} from "./stores/reducers/page";
import {updatedActions} from "./stores/reducers/page-updates";
import {fetchInit} from "./util/requests";
import {pagesActions} from "./stores/reducers/pages";
import { Routes } from "react-router";
import Page from "./components/Pages/Page";


function App() {

  const dispatch = useDispatch();
  const [head, setHead] = useState('');
  const [fetched, setFetched] = useState(false);
  const pages = useSelector(state => state.pages.pages);
  const update_next = useSelector(state => state.updated.pages);
  const pathname = window.location.pathname;
  const hasPage = (pathname in pages);

  useLayoutEffect(() => {
    // is the page already stored in redux store
    // and is it not marked as modified
    if(hasPage && !(pathname in update_next)) {
      dispatch(pageActions.setCurrent(pages[pathname]));
      setHead(  typeof pages[pathname].seo_head !== "undefined" ? pages[pathname].seo_head : '');
    }
    else {
      // if page has been modified, re-fetch it and remove
      // it from list of modified pages in redux store
      if((pathname in update_next)){
        dispatch(updatedActions.removePage(pathname));
      }
      if(!fetched) {
        setFetched(true);

        fetchInit().then((data) => {
          if( typeof data.menus !== 'undefined' ) {
            dispatch(themeActions.setMenus(data.menus));
          }
          if( typeof data.options !== 'undefined' ) {
            dispatch(themeActions.setOptions(data.options));
          }
          if( typeof data.events !== 'undefined' ) {
            dispatch(eventsActions.fillEvents(data.events));
          }
          if( typeof data.page.seo_head !== 'undefined' ) {
            setHead(  typeof data.page.seo_head !== "undefined" ? data.page.seo_head : '');
          }
          if( typeof data.page !== 'undefined' ) {
            dispatch(pagesActions.addPage({
              'slug': pathname,
              'page': data.page
            }));
          }

          dispatch(themeActions.setIsLoaded(true));
        }).catch((error) => {
          console.log(error);
        });
      }
    }
  }, [setFetched, dispatch, fetched, hasPage, pages, pathname, update_next])


  const isLoaded = useSelector(state => state.theme.isLoaded);
  if(!isLoaded) {
    return ''
  }

  return (
    <React.Fragment>
      <Helmet>{parse(head)}</Helmet>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Page />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </React.Fragment>
  );
}

export default App;
