import React, {useEffect, useState} from "react";

import Title from "../Title/Title";
import SubTitle from "../SubTitle/SubTitle";
import Event from "./Event";
import {useSelector} from "react-redux";
import styles from './Events.module.scss';
import {fetchPastEvents} from "../../util/requests";

const Events = ( props ) => {

  const events = useSelector(state => state.events.events);
  const [loadedMore, setLoadedMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pastEvents, setPastEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
      if(pastEvents.length === 0 &&  events['upcoming']) {
        setUpcomingEvents ( events['upcoming'].map((event) =>
          <Event key={event.title}
                 className={styles.event}
                 event={event}
                 past={false}
          >
          </Event>
        ));

        setPastEvents ( events['past'].map((event) =>
          <Event key={event.title}
                 className={styles.event}
                 event={event}
                 past={true}
          >
          </Event>
        ));
      }
  }, [pastEvents, upcomingEvents, setPastEvents, setUpcomingEvents, events])

  const loadMoreEvents = () => {
    setLoading(true);

    fetchPastEvents().then((data) => {
      if( typeof data['past'] !== 'undefined' ) {
        setPastEvents ( data['past'].map((event) =>
          <Event key={event.title}
                 className={styles.event}
                 event={event}
                 past={true}
          >
          </Event>
        ));
      }
      setLoadedMore(true);
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className={styles.events}>
      <SubTitle>upcoming</SubTitle>
      <Title>shows</Title>
      <table className={styles.table}>
        <thead>
        <tr>
          <th className={styles.titleDate}>date</th>
          <th className={styles.titleVenue}>venue</th>
          <th className={styles.titleLocation}>location</th>
          <th className={styles.titleTickets}>tickets</th>
        </tr>
        </thead>
        <tbody>
        {upcomingEvents}
        </tbody>
      </table>


      <div className={loadedMore ? styles.pastWrapperLoaded : styles.pastWrapper}>
        <SubTitle>past shows</SubTitle>

        <table className={styles.pastTable}>
          <tbody>
          {pastEvents}
          <tr>
            <td colSpan='4'>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      {
        loadedMore ? '' : (
          <div className={ loading ? styles.loadingMore : styles.loadMore } onClick={loadMoreEvents}>
            <span className={styles.ticketText}>
              load more
            </span>
          </div>
        )
      }

    </div>
  );
};
export default Events;
