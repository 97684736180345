import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: {},
};

const updatedSlice = createSlice( {
  name: 'updated',
  initialState: initialState,
  reducers: {
    addPage(state, action) {
      state.pages[action.payload.slug] = {};
    },
    removePage(state, action) {
      delete state.pages[action.payload];
    },
  }
} );

export const updatedActions = updatedSlice.actions;
export default updatedSlice.reducer;
