import React from "react";

import Container from "../Layout/Container";
import styles from './About.module.scss';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";

import { Autoplay, EffectFade} from "swiper";
import Image from "../Image/Image";

const About = ( props ) => {

  let bio_de = props.attrs.data['block_about_bio_de'];
  let bio_en = props.attrs.data['block_about_bio_en'];
  let slides = [];
  let images = [];

  let switchLanguage = () => {
    document.getElementById('bioDe').classList.toggle(styles.isHidden);
    document.getElementById('switchDe').classList.toggle(styles.isHidden);
    document.getElementById('bioEn').classList.toggle(styles.isVisible);
    document.getElementById('switchEn').classList.toggle(styles.isVisible);
  }

  bio_de = bio_de.replace(/(?:\r\n|\r|\n)/g, '<br />');
  bio_en = bio_en.replace(/(?:\r\n|\r|\n)/g, '<br />');

  if(typeof props.attrs !== 'undefined') {
    for(let i = 0; i < props.attrs.data['block_about_images']; i++) {
      images.push(props.attrs.data['block_about_images_' + i + '_block_about_image']);
    }
  }
  else {
    images = props.images;
  }

  for(let i = 0; i < images.length; i++) {
    slides[i] = (
      <SwiperSlide key={i} className={styles.slide}>
        <div className={styles.image}>
          <Image
            src={images[i]['sizes']['full']['src']}
            alt={images[i]['alt']}
            width={images[i]['sizes']['full']['width']}
            height={images[i]['sizes']['full']['height']}
            lazyload={true}
          />
        </div>
      </SwiperSlide>
    );
  }


  return (
    <div className={styles.about}>
      <div className={styles.imageHolder}>
        <Swiper className={styles.swiper}
                loop={true}
                speed={3000}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                effect={"fade"}
                allowTouchMove={false}
                slidesPerView={1}
                modules={[Autoplay, EffectFade]}
        >
          {slides}
        </Swiper>

          <div className={styles.textHolder}>
            <Container>
              <div className={styles.textInner}>
                <div className={styles.textDe} id={'bioDe'}>
                  {parse( bio_de )}
                </div>
                <div className={styles.textEn} id={'bioEn'}>
                  {parse( bio_en )}
                </div>

                <div className={styles.textHeader}>
                  <div className={styles.textPreTitle}>about</div>
                  <div className={styles.textTitle}>BOSStria</div>
                </div>

                <div className={styles.switchHolder} >
                  <div className={styles.switch} onClick={switchLanguage}>
                    <div className={styles.switchDe} id={'switchDe'}>
                      read in english
                    </div>
                    <div className={styles.switchEn} id={'switchEn'}>
                      auf deutsch lesen
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
      </div>
    </div>
  );
};
export default About;
