import React, {useState, useEffect, useLayoutEffect} from "react";

import Container from "../Layout/Container";

import styles from './Header.module.scss';
import Row from "../Layout/Row";
import Column from "../Layout/Column";
import {useSelector} from "react-redux";
import classNames from "classnames/bind";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const classes = classNames.bind(styles);

const Header = ( props ) => {

  const [isScrolled, setisScrolled] = useState(false);
  const [init, setInit] = useState(true);
  const items = useSelector(state => state.theme.menus);
  let menu = '';

  useLayoutEffect(() => {
    if(init) {
      let href = window.top.location.hash.substr(1);
      if(href) {
        setTimeout(() => {
          let element = document.getElementById(href);
          if(element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }, 500);
      }
    } else {
      setInit(false);
    }
  }, [init])

  let handleClick = (e) => {
    e.preventDefault();
    let href = e.target.getAttribute("href");
    if(href.indexOf("#") !== -1) {
      if(href[0] === '/') {
        href = href.replace('/','');
      }
      href = href.replace('#','');
      let element = document.getElementById(href);
      if(element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });

        setTimeout(function(){
          let burger = document.getElementById('burger');
          if(burger.classList.contains(styles.isOpen)) {
            burger.classList.remove(styles.isOpen);
            let navigation = document.getElementById('navigation');
            navigation.classList.remove(styles.isOpen);
            let content = document.getElementById('main-content');
            content.classList.toggle(styles.hasOpenMenu);
            enableBodyScroll(navigation);
          }
        }, 400);
      }
      else {
        window.location = '/#'+href;
      }
    }
    else {
      window.location = href;
    }
  };

  if(typeof items !== 'undefined') {
    const navItems = items['main-menu'].items.map((item) =>
      <a key={item.title}
            className={styles.navItem}
            href={item.url.replace(window.location.origin, '')}
            onClick={handleClick}
      >
        {item.title}
      </a>
    );
    menu = <div className={styles.navigation} id={"navigation"}>{navItems}</div>;
  }


  useEffect(() => {
    const handleScroll = event => {
      setisScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  let toggleBurger = () => {
    let burger = document.getElementById('burger');
    let content = document.getElementById('main-content');
    burger.classList.toggle(styles.isOpen);
    content.classList.toggle(styles.hasOpenMenu);
    let navigation = document.getElementById('navigation');
    navigation.classList.toggle(styles.isOpen);

    if(navigation.classList.contains(styles.isOpen)) {
      disableBodyScroll(navigation);
    }
    else {
      enableBodyScroll(navigation);
    }
  }


  const isLoaded = useSelector(state => state.theme.isLoaded);
  if(!isLoaded) {
    return ''
  }

  return (
    <header className={
      classes({
        header: true,
        isScrolled: isScrolled
      } ) }
    >
      <Container>
        <Row align="middle" justify="between" className={styles.headerRow}>
          <Column width={3} className={styles.headerCol}>
            <a className={styles.title} href={'/'}>
              BOSStria
            </a>
          </Column>

          <Column width={9} className={styles.headerCol}>
            {menu}

            <div className={styles.burger} id={'burger'} onClick={toggleBurger}>
              <span className={styles.burgerLine}></span>
              <span className={styles.burgerLine}></span>
              <span className={styles.burgerLine}></span>
            </div>
          </Column>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
