import React from "react";

import Container from "../Layout/Container";

import styles from './Footer.module.scss';
import Title from "../Title/Title";

import { ReactComponent as Facebook } from '../../assets/images/facebook-square.svg';
import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';
import { ReactComponent as Youtube } from '../../assets/images/youtube.svg';
import {useSelector} from "react-redux";
import Image from "../Image/Image";


const Footer = ( props ) => {

  const isLoaded = useSelector(state => state.theme.isLoaded);
  const youtube_link   = useSelector(state => state.theme.options.youtube);
  const facebook_link  = useSelector(state => state.theme.options.facebook);
  const instagram_link = useSelector(state => state.theme.options.instagram);
  const footer_image   = useSelector(state => state.theme.options.footer_image);

  if(!isLoaded) {
    return ''
  }

  return (
    <footer className={styles.mainFooter}>
      <div className={styles.footerImg}>
        <Image
          src={footer_image['sizes']['full']['src']}
          alt={footer_image['alt']}
          width={footer_image['sizes']['full']['width']}
          height={footer_image['sizes']['full']['height']}
          lazyload={true}
        />
      </div>
      <Container>
        <div className={styles.footerInner}>
          <Title>find us on</Title>
          <div className={styles.social}>
            <a href={facebook_link} target={'_blank'} rel={"noreferrer"}>
              <Facebook className={styles.icon}/>
            </a>
            <a href={instagram_link} target={'_blank'} rel={"noreferrer"}>
              <Instagram className={styles.icon}/>
            </a>
            <a href={youtube_link} target={'_blank'} rel={"noreferrer"}>
              <Youtube className={styles.icon}/>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default Footer;
