import React from "react";
import classNames from "classnames/bind";

import styles from './Row.module.scss';
const cn = classNames.bind(styles);

const Row = ( props ) => {

  const justify = props.justify ? props.justify.toString().toLowerCase() : 'start';
  const align = props.align ? props.align.toString().toLowerCase() : 'top';

  return <div className={cn({
    'row': true,
    [`align--${align}`]: true,
    [`justify--${justify}`]: true
  })}>{props.children}</div>;
};

export default Row;
