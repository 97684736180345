import React from "react";

import styles from './PageIntro.module.scss';


const PageIntro = ( props ) => {

  let image = props.attrs.data['block_page-intro_image'];
  // let background = props.attrs.data['block_page-intro_background'];
  let logo = props.attrs.data['block_page-intro_logo'];
  // let link = props.attrs.data['block_page-intro_link'];

  function parallax() {
    var s = document.getElementById("logo");
    var yPos = window.pageYOffset / 15;
    s.style.bottom = yPos + "%";
  }

  window.addEventListener("scroll", function(){
    parallax();
  });

  const scrollDown = () => {
    document.getElementById('events').scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <div className={styles.pageIntro}>
      <div className={styles.imageHolder}>
        <img src={image['sizes']['full']['src']} className={styles.image} alt={image['alt']}
             width={image['sizes']['full']['width']} height={image['sizes']['full']['height']} />

        <img src={logo['sizes']['full']['src']} className={styles.logo} id="logo" alt={logo['alt']}
             width={logo['sizes']['full']['width']} height={logo['sizes']['full']['height']} />
      </div>

      <div id="mouse-scroll" className={styles.scroller}>
        <div className={styles.mouse}  onClick={scrollDown}>
          <div className={styles.mouseIn}></div>
        </div>
        <div  onClick={scrollDown}>
          <span className={styles.downArrow1}></span>
          <span className={styles.downArrow2}></span>
          <span className={styles.downArrow3}></span>
        </div>
      </div>
    </div>
  );
};
export default PageIntro;
