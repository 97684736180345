import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchLastModified} from "../../util/requests";
import {updatedActions} from "../../stores/reducers/page-updates";


/**
 * The PageUpdater searches for pages, which are stored in the
 * redux store already and have to be fetched again at next visit.
 * Therefore, it calls an API request with all stored page IDs.
 * If there is a 'last_modified' value older than the locally
 * stored one, save the slug/pathname to the page-updates store.
 * The App.js will check this list at page refresh.
 **/
const PageUpdater = ( props ) => {

  const dispatch = useDispatch();
  const pages = useSelector(state => state.pages.pages);
  const update_next = useSelector(state => state.updated.pages);

  useEffect(() => {
    if(Object.keys(pages).length > 0) {
      let ids  = Object.entries( pages ).map( ( [slug, page] ) => {
        return page.id;
      });
      fetchLastModified(ids).then( data => {
        for ( const [ id, value ] of Object.entries( data ) ) {
          // eslint-disable-next-line
          let page = Object.entries( pages ).filter( ( [slug, page] ) => {
            // eslint-disable-next-line
            if(page.id == id && !page.update_next) {
              return page;
            }
          });

          if(page.length > 0 && !(page[0][0] in update_next)) {
            let current = new Date(page[0][1].last_updated);
            let last = new Date(value.last_updated);
            if(current < last) {
              dispatch(updatedActions.addPage({
                'slug': page[0][0],
              }));
            }
          }
        }
      } ).catch( error => {
        console.log(error)
      } );
    }
  })
};
export default PageUpdater;
