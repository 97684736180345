import React from "react";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import Image from "../Image/Image";
import parse from "html-react-parser";
import styles from './ImageText.module.scss';
import buttonStyles from '../Contact/Contact.module.scss';
import {enableBodyScroll} from "body-scroll-lock";


const ImageText = ( props ) => {

  let text = props.attrs.data['block_image-text_text'];
  let image_data = props.attrs.data['block_image-text_image'];
  let button = props.attrs.data['block_image-text_link'];

  let handleClick = (e) => {
    e.preventDefault();
    let href = e.target.getAttribute("href");
    if(href.indexOf("#") !== -1) {
      if(href[0] === '/') {
        href = href.replace('/','');
      }
      href = href.replace('#','');
      let element = document.getElementById(href);
      if(element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
      else {
        window.location = '/#'+href;
      }
    }
    else {
      window.location = href;
    }
  };


  return <div className={styles.imageText}>
    <Row>
      <Column width={6} breakpoints={{ m: 12 }}>
        <div className={styles.imageHolder}>
          <Image src={image_data['sizes']['full']['src']}
                 alt={image_data['alt']}
                 width={image_data['sizes']['full']['width']}
                 height={image_data['sizes']['full']['height']}
                 lazyload={true}
          />
        </div>
      </Column>
      <Column width={6} breakpoints={{ m: 12 }}>
        <div className={styles.text}>
          {parse(text)}
        </div>
        <a className={buttonStyles.oButton} href={button['url']} onClick={handleClick}>
          {button['title']}
        </a>
      </Column>
    </Row>
  </div>

};
export default ImageText;
